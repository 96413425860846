import React, { useState, useEffect, useRef } from 'react';
import ExperienceItem from './EducationItem'; // Make sure to import ExperienceItem
import styles from './Education.module.css';

const experiences = [
    { id: 1, title: "Masters in Information Technology", date: "Aug 2022 - Dec 2023", company: "University of Cincinnati", description: `• Mastered advanced concepts in computer science, information technology including machine learning, data engineering, software engineering, and business intelligence through a rigorous Master's program.
    • Relevant coursework: Machine Learning and Data Mining, Cloud Computing(Azure, AWS), Cyber Security, System Administration, Business Intelligence, IT Research Methods, and more.
    ` },
        { id: 2, title: "Bachelors in Computer Engineering", date: "Aug 2015 - Aug 2019", company: "Visvesvaraya Technological University", description: `• Immersing myself in the world of Computer Engineering throughout my Bachelor's journey, I delved deep into the fusion of hardware and software realms, delving into topics ranging from digital systems design and computer architecture to database systems. Through hands-on projects and rigorous coursework, I cultivated invaluable skills to tackle intricate engineering hurdles, laying a robust foundation for my future endeavors in the realm of technology.
    • Relevant coursework: Objected-oriented Programming with C, Java, Computer Organization, Machine Learning, Data Structures, Python Programming, Web Technologies, and more.` }
];

const Experience = () => {
    const [expandedId, setExpandedId] = useState(null);

    return (
        <div id="education" tabIndex={0} aria-label='education section' className={`container justify-content-center mt-4 ${styles.experienceContainer}`}>
            <h2 className={`text-center ${styles.experienceHeading} ${styles.sectionh2}`}>Education</h2>
            <div className={`row justify-content-center`}>
                <div className="col-md-10 col-md-offset-1">
                    <div className={`${styles.experienceContent}`}>
                        <div className={`${styles.cdTimeline}`}>
                            {experiences.map((experience, index) => (
                                <ExperienceItem key={experience.id} experience={experience} index={index} expandedId={expandedId} setExpandedId={setExpandedId} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience;