import React, { useState } from 'react';
import ExperienceItem from './ExperienceItem'; // Make sure to import ExperienceItem
import styles from './Experience.module.css';

const experiences = [
    { id: 1, title: "Full Stack Developer", date: "Aug 2023 - Present", company: "Changing The Present", description: `
    ● Transformed the user interface of a charity gift purchase web app using JavaScript, implementing customized login/logout features to bolster security measures. This initiative resulted in a notable 56% increase in new customer subscriptions, enhancing user engagement & satisfaction.
    ● Utilized SQL queries to extract data from Shopify, and then employed Python's Pandas library to meticulously craft data views tailored for seamless integration with Power BI. This strategic initiative precipitated a remarkable 65% enhancement in website efficiency and functionality.
    ● Designed front-end framework with React.js Using class component and functional component.
    ● Built and maintained a web application using Python, JavaScript, HTML, and CSS, resulting in a 25% increase in user engagement and a 20% increase in revenue within the first six months.
    ● Created impactful dashboards in Excel and Tableau for data reporting. Extracted data, interpreted, and analyzed data using Excel to identify key metrics and transform data into meaningful, actionable information. 
    ` },

    { id: 2, title: "Full Stack Developer", date: "Mar 2023 - May 2023", company: "University of Cincinnati", description: `
    ● Architected and optimized the OSDP web app using Node.js, React.js, and Java leading to significant user experience improvement and a 70% increase in application responsiveness.
    ● Implemented React Hooks (State Hook, Effect Hook, Ref Hook and so on) to use state and lifecycle methods inside functional components.
    ● Initiated comprehensive data security protocols leveraging encryption software BitLocker and access control systems like IAM using AWS IAM, bolstering company data protection measures.
    ● Collaborated with a team of developers to create and maintain technical documentation, improving overall team efficiency by 20%.
    ● Optimized application performance by monitoring and analyzing application logs, resulting in a 15% increase in efficiency and a 10% reduction in response time.
    ● Hands-on experience in NoSQL Database (MongoDB), data modeling and schema design.` },
    
    { id: 3, title: "Software Engineer", date: "Sep 2019 - Jul 2022", company: "Accenture", description: `
    ● Employed elicitation techniques to gather, analyze, refine, and document business requirements, ensuring alignment with diverse user groups across the APAC, EMEA, LATAM, and NA regions, with the support of tools like Microsoft Visio for visual representation and JIRA for requirement tracking.
    ● Leveraged JavaScript to automate data reporting processes, resulting in a remarkable 70% reduction in analysis time, while concurrently optimizing Standard Operating Procedures (SOPs) with ARIS to enhance application efficiency during peak usage, with the aid of tool such as Power BI for visualization.
    ● Efficiently managed and resolved a high volume of user incident requests for the ARIS BPM tool using BMC Remedy, ensuring timely resolution and maintaining high user satisfaction.
    ● Crafted user-friendly interfaces using JavaScript frameworks, improving user experience and increasing system efficiency by 20%.
    ● Reduced security vulnerabilities by 35% by incorporating AWS Identity and Access Management (IAM).
    ● Enhanced database performance using SQL, leading to 15% decrease in response time.
    ● Collaborated on APIs and managed software workflow using Scrum methodology, increasing task success rate by 25%.
    ● Employed tools like AWS CloudWatch and AWS Trusted Advisor to enhance AWS utilization. Implemented constant performance monitoring and tuning, guaranteeing optimal application availability and performance.` },
];
const Experience = () => {
    const [expandedId, setExpandedId] = useState(null);

    return (
        <div id="experience" tabIndex={0} aria-label='experience section' className={`container justify-content-center mt-4 ${styles.experienceContainer}`}>
            <h2 className={`text-center ${styles.experienceHeading} ${styles.sectionh2}`}>Experience</h2>
            <div className={`row justify-content-center`}>
                <div className="col-md-10 col-md-offset-1">
                    <div className={`${styles.experienceContent}`}>
                        <div className={`${styles.cdTimeline}`}>
                            {experiences.map((experience, index) => (
                                <ExperienceItem key={experience.id} experience={experience} index={index} expandedId={expandedId} setExpandedId={setExpandedId} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience;