import React, {useState} from 'react';
import styles from './Projects.module.css';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ProjectModal from './ProjectModal';
import DataAnalysis from '../images/DataAnalysis/Data analysis.png';
import IOT_System from '../images/IOT_System/IOT.jpg';

const projectList = [
  {
    id: 1,
    title: 'Exploratory Data Analysis on Crime Data',
    short_description: 'Conduct in-depth data analysis to derive insights about specific crime types using data from the 84.51’C organization.',
    long_description: '•	Undertook a comprehensive exploratory data analysis to gain insights into specific crime categories using a data lake sourced from the 84.51’C organization. Utilized tools like DataBricks, Spark, Python, and SQL to process and analyze the data, deriving actionable insights.',
    technologies: ['Python', 'Databricks', 'Spark', 'SQL'],
    thumbnail: DataAnalysis,
    images: [],
    link: "https://github.com/Gopal2233/Exploratory-Data-Analysis"
  },
  {
    id: 2,
    title: 'IoT Based Automated Healthcare Monitoring System',
    short_description: 'An IoT-based health monitoring system to track vital health metrics.',
    long_description: 'Designed and developed an IoT-based system using Java, MySQL, integrated with machine learning for real-time decision-making.',
    technologies: ['Java', 'MySQL', 'ML', 'AWS'],
    thumbnail: IOT_System,
    images: [],
    link: "https://github.com/Gopal2233/IOT-Based-Healthcare-Monitoring-System"
  },
  // {
  //   id: 3,
  //   title: 'Covid Detection',
  //   short_description: 'A web application that detects Covid and also features an AI ChatBot that provides recovery suggestions.',
  //   long_description: 'Covid Detection is a web application that utilizes a deep learning model to detect Covid-19 using CT scan images. The application also features an AI ChatBot that provides recovery suggestions to users. The deep learning model is trained on a dataset of CT scan images to accurately detect Covid-19 in patients.',
  //   technologies: ['Python', 'Flask', 'Keras', 'TensorFlow', 'NumPy', 'OpenAI', 'HTML', 'CSS', 'JavaScript'],
  //   thumbnail: covid_detector,
  //   images: [covid_landing, covid_login, covid_dashboard, questionnaire, results, chatbot, register],
  //   link: "https://github.com/khtrivedi84/covid-19-detection"
  // }
];


const ProjectCard = ({ project, index, openModal }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const handleKeyDown = (event, project) => {
    // Check if Enter or Space key is pressed
    if (event.key === 'Enter' || event.key === ' ') {
      // Prevent the default action to stop scrolling when space is pressed
      event.preventDefault();
      // Simulate the click action
      openModal(project);
    }
  };
  

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      custom={index}
      variants={{
        hidden: { opacity: 0, translateY: 50 },
        visible: {
          opacity: 1,
          translateY: 0,
          transition: { delay: index * 0.1, type: 'spring', stiffness: 120 }
        },
      }}
      className={`col-lg-3 col-md-4 col-sm-6 ${styles.card2} mx-md-3`}
      onClick={() => openModal(project)}
      tabIndex={0}
      onKeyDown={(event) => handleKeyDown(event, project)}
      aria-label={`View project details for ${project.title}`}
    >
      <div className={`${styles.cardImageContainer}`}>
        <img src={project.thumbnail} alt={project.title} className={styles.cardImage} />
      </div>
      <div className={styles.cardBody}>
        <h5 className={styles.cardTitle}>{project.title}</h5>
        <p className={styles.cardDescription}>{project.short_description}</p>
      </div>
    </motion.div>
  );
};

const Projects = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const openModal = (project) => {
    setSelectedProject(project);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedProject(null);
  };

  return (
    <div id="projects" tabIndex={0} aria-label='projects section' className={`container justify-content-center mt-4 ${styles.projectsContainer}`}>
      <h2 className={`text-center mt-4 text-light ${styles.sectionh2}`}>Projects</h2>
      <div className={`row ${styles.cardsRow}`}>
        {projectList.map((project, index) => (
          <ProjectCard
            key={project.id}
            project={project}
            index={index}
            openModal={openModal}
          />
        ))}
      </div>
      {selectedProject && (
        <ProjectModal
          isOpen={modalIsOpen}
          closeModal={closeModal}
          project={selectedProject}
        />
      )}
    </div>
  );
};

export default Projects;