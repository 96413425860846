import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from "./AboutMe.module.css";
import profile from "../images/profile/profilepic.jpg";

const AboutMe = () => {

    const { ref, inView } = useInView({
        threshold: 0.3, // Trigger when 30% of the element is in view
        triggerOnce: true // Only trigger once
        });
  useEffect(() => {
    const skillsItems = document.querySelectorAll(`.${styles.skills__item}`);
    skillsItems.forEach(item => {
        const delay = Math.random() * 2; // Random delay between 0 and 2 seconds
        item.style.animationDelay = `${delay}s`;
    });
  }, []);

  return (
    <div ref={ref} tabIndex={0} aria-label='About me section' id="aboutme" className={`${styles.centerDiv} container`}>
        <div className={`row justify-content-center align-items-center text-white`}>
            <h2 className={`text-center text-light ${styles.sectionh2}`} style={{marginBottom:"2rem"}}>About Me</h2>
            <div className={`col-md-6 justify-content-center d-flex mb-4`}>
                <div className={`${styles.imageDiv} ${styles.hideDiv} ${styles.glowingBorder} ${inView ? styles.animateFromLeft : ''}`}>
                    <img src={profile} alt="HTML" className={`${styles.profileImage}`} />
                </div>
            </div>
            <div className="col-sm-12 col-md-6">
                <div className={`about-me ${styles.hideDiv} ${inView ? styles.animateFromRight : ''}`}>
                    <p className="about-me__text">
                    Hello! 👋 I'm Gopal Adusumilli, a dynamic software engineer with a passion for creating robust and scalable software solutions. I hold a Master of Science in Information Technology from the University of Cincinnati. Proficient in Java, Python, JavaScript, and a variety of frameworks, I thrive in the dynamic interplay between creativity and technology in web development.
                    </p>
                    <p className="about-me__text">
                    I enjoy going on new culinary adventures, photographing beautiful moments with my lens 📷, and losing myself in a good book 📚 when I'm not engrossed in the digital world. I enjoy discovering the rich tapestry of experiences life has to offer, whether I'm scheming in the kitchen, hunting for the ideal shot, or immersing myself in a gripping narrative. So let's go off on this journey together, where each moment offers a chance to learn something new and each adventure offers an opportunity to make lifelong memories.
                    </p>
                    <p className={`about-me__text d-md-block ${styles.optionalPara}`}>
                    I have experience working with a variety of technologies, including React.js, Node.js, SQL, Power BI, AWS, Azure, and more. I am always looking to learn new technologies and expand my skill set.
                    </p>
                    <p className="about-me__text">
                    Let's connect and share stories—exploring the exciting journey where technology meets imagination. Ready to embark on this journey together?
                    </p>
                </div>
            </div>
            
        </div>
    </div>
  );
};

export default AboutMe;